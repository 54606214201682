import React, {lazy, Suspense } from 'react';
import Header from './components/pages/Header';
import About from './components/pages/About';
//import Works from './components/pages/Works';
import Contact from './components/pages/Contact';
import OurServices from './components/pages/OurServices';
// const Header = lazy(() => import('./components/pages/Header'));
// const About = lazy(() => import('./components/pages/About'));
// //const Works = lazy(() => import('./components/pages/Works'));
// const Contact = lazy(() => import('./components/pages/Contact'));
// const OurServices = lazy(() => import('./components/pages/OurServices'));


function App() {
//   useEffect(() => {
//     AOS.init({
//       duration: 750,
//       once: true
//     })
// // eslint-disable-next-line
//   }, [])

  return (
    <div className="App">
      {/* <Suspense fallback={<div>Page is Loading...</div>}> */}
      <Header />
      <OurServices />
      <About />
      {/* <Works /> */}
      <Contact />
      {/* </Suspense> */}
    </div>
  );
}

export default App;
