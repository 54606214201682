import React from 'react';
import { ourServices } from '../../helpers/profile';
import {DesignLogo, DevelopmentLogo, PaymentLogo, UserManageLogo, SEOLogo, ReportingLogo } from '../../helpers/icons';


const OurServices: React.FC = () => {
  return (
    <div className="container container-fluid pt-5">
    <div className="d-flex justify-content-center">
                <h1 id="test1" className="text-center text-primary border-bottom border-primary w-50 pt-5 ">Our Services</h1>
              </div>
              <div className="container container-fluid ">
                <div className=" row justify-content-center ">
                  <div className=" card  col-md-3 m-3  " style={{width: '18rem', height: '24rem'}}>
                    <DesignLogo className="card-img-top pt-3" />
                    <div className="card-body">
                      <h4 className="card-title ">Design</h4>
                      {ourServices.design}
                    </div>
                  </div>
                  <div className=" col-md-3 card m-3" style={{width: '18rem', height: '24rem'}}>
                    <DevelopmentLogo  className="card-img-top pt-3" />
                    <div className="card-body">
                      <h4 className="card-title ">Development</h4>
                      {ourServices.development}
                    </div>
                  </div>
                  <div className="col-md-3  card m-3" style={{width: '18rem', height: '24rem'}}>
                   <PaymentLogo className="card-img-top pt-3" />
                    <div className="card-body">
                      <h4 className="card-title ">Payments</h4>
                      {ourServices.payment}
                    </div>
                  </div>
                  <div className="col-md-3  card m-3" style={{width: '18rem', height: '24rem'}}>
                    <UserManageLogo className="card-img-top pt-3" />
                    <div className="card-body">
                      <h4 className="card-title ">User Management</h4>
                      {ourServices.userManagement}
                    </div>
                  </div>
                  <div className="col-md-3  card m-3" style={{width: '18rem', height: '24rem'}}>
                    <img
                      src="uSEO.svg"
                      alt="Searching icon"
                      className="card-img-top pt-3"
                    />
                    <div className="card-body">
                      <h4 className="card-title ">SEO</h4>
                      {ourServices.seo}
                    </div>
                  </div>
                  <div className="col-md-3  card m-3" style={{width: '18rem', height: '24rem'}}>
                     <img
                      src="uReporting.svg"
                      alt="Designing pic"
                      className="card-img-top  pt-3"
                      style={{height: '11rem'}}
                    /> 
                    <div className="card-body">
                      <h4 className="card-title ">Reporting</h4>
                      {ourServices.reporting}
                    </div>
                  </div>
                </div>
              <h3 className="text-center">and more...</h3>
              <p className="text-center">{ourServices.andMore}</p>
          </div>
    </div>
  )
}

export default OurServices;