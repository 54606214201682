import React from 'react';
import { about } from '../../helpers/profile';



const About: React.FC = () => {

    return (
      <>
        <div id="about" className="container container-fluid">  
          <div className=" d-flex justify-content-center">
            <h1 className="text-primary text-center border-bottom border-primary w-50">About us</h1>
          </div>
          <div className="row justify-content-center " style={{height: '23rem'}}>
            <div
              data-aos="zoom-in-up"
              data-aos-once="true"
              id="not"
              className='col-md m-3'
  
            >
                <h3 id="About" className="text-primary text-center">
                  We design and build from the ground up.
                </h3>
              <p className="">{about.paragraph}</p>
            </div>

              <div  data-aos="zoom-in-up"  className="col-md m-3" id="not">
                <h3 id="About" className="text-primary text-center ">
                  We love what we do, and what you do.
                </h3>
                <p className="">{about.paragraph1}</p>
              </div>
            
          </div>
        </div>
      </>
    );
}

export default About
