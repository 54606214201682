// Change your display name on tha landing display
const header = {
  name: "Moe Media",
}
const background = {
  // Options: Snow or Particle
  type: "Particle"
}
// Write a para about yourself here
// To update your image, go to './styles/images.css'
const about = {
  paragraph: "Programming all of our sites from scratch means we can meet any business requirement for any project. Sure, DIY web solutions like Shopify and Wix work just fine...until you need some level of customization. Therein lies biggest benefit of choosing Moe Media - if you can dream it, we can build it.",
  paragraph1:`We're just as passionate about building software as we are about seeing your business thrive. When you choose Moe Media as your technology partner, rest assured that we are fully committed to growing your brand and improving your business through our technology. Just ask our clients!`
}

const ourServices = {
  design:`From system architecture to web layouts and imagery, we'll give your brand an effective digital voice...with good looks to boot.`,
  development:'Our technology is built to industry standards using the latest and greatest software development tools, ensuring your most niche business needs are effectively covered.',
  payment:`We partner with Stripe and Square to seamlessly and securely manage all of your customer payments and revenue streams.`,
  userManagement:`We ensure your customer accounts are handled securely through proper authentication and user management systems.`,
  seo: `Our tech toolkit and internet expertise will ensure your website is optimized for search engines to enable you to connect with your future customers.`,
  reporting:`We give you direct access to all your business data with custom reports to help you make informed decisions through meaningful insights.`,
  andMore:`We are your long-term technology partner, ready to build and manage everything your web platform needs to thrive.`
}
// Edit your skill and the percentage you know about it
// To Add a skill, copy any one below and paste it after the last comma
const skillsBar = [
  {
      name: "HTML5",
      value: 75
  },{
      name: "CSS3",
      value: 85
  },{
      name: "Javascript",
      value: 80
  },{
      name: "React",
      value: 75
  },{
      name: "Node",
      value: 85
  },{
      name: "Mongoose",
      value: 70
  },{
      name: "Adobe XD",
      value: 75
  },
]
// Edit your projects, its name, your skills used to make it, and the url. 
// You can omit freely anything if you dont have it
// To Add a Project, copy any one below and paste it after the last comma and increment the id's project number
const projects = [
  {
      // Add image in './styles/images.css' in #project1
      id: "project1",
      name: "Project 1",
      skills: ["HTML, CSS, JS"],
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #project2
      id: "project2",
      name: "Project 2",
      skills: ["HTML, CSS, JS"],
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #project3
      id: "project3",
      name: "Project 3",
      skills: ["HTML, CSS, JS"],
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #project4
      id: "project4",
      name: "Project 4",
      skills: ["HTML, CSS, JS"],
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #project5
      id: "project5",
      name: "Project 5",
      skills: ["HTML, CSS, JS"],
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #project6
      id: "project6",
      name: "Project 6",
      skills: ["HTML, CSS, JS"],
      url: "https://github.com/kaustubhai"
  },
]
// Edit your Miscellaneous Activities, its name and the url. 
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
const miscellaneous = [
  {
      // Add image in './styles/images.css' in #misc1
      id: "misc1",
      name: "Miscellaneous 1",
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #misc2        
      id: "misc2",
      name: "Miscellaneous 2",
      url: "https://github.com/kaustubhai"
  },
  {
      // Add image in './styles/images.css' in #misc3
      id: "misc3",
      name: "Miscellaneous 3",
      url: "https://github.com/kaustubhai"
  },
]
// Contact form text, and Jotforms link
// To get your own jotform link, go to https://www.jotform.com/
// If you hacve the link already, paste it in the contactUrl below
const contact = {
  pitch: "Feel free to follow me via: ",
  copyright: " Moe Media, LLC",
  contactUrl: 'https://formspree.io/f/xzbkkqjl'
}
// Paste your respective social media links. You can omit any if you dont have it
// Upload your resume in your drive, get the shaareable link and paste it in the resume section
const social = {
  github: 'https://github.com/gant123',
  facebook: 'https://facebook.com',
  linkedin: 'https://www.linkedin.com/in/robert-gant-258978105/',
  instagram: 'https://instagram.com',
  resume: 'https://novoresume.com/'
}
// Dont change anything here
export { header, background, about, skillsBar, projects, miscellaneous, contact, social, ourServices }