import React, { useState } from 'react';
import { SwitchComponent, ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {  SidebarComponent} from '@syncfusion/ej2-react-navigations';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import * as Scroll from 'react-scroll';
import Particles from '../layout/Particles';

interface Props {

}



const Header: React.FC<Props> = () => {
const [switches, setSwitch] = useState<any>({});
const [sidebarobj, setSidebarobj] = useState<any>({});


let Link  = Scroll.Link;
let Element   = Scroll.Element;
const listTemplate = (data:any):any => {
    return (<Link activeClass="active" to="test1" spy={true} smooth={true} duration={100}>
    {data.text}
  </Link>);
};

let data = [
    { text: 'Services', id: 'list-01' },
    { text: 'About', id: 'list-02' },
    { text: 'Showcase', id: 'list-03' },
    { text: 'Contact', id: 'list-04' }
];





const toggleClick = (): void => {
    sidebarobj.element.style.visibility = '';
     sidebarobj.toggle();
}
 const closeClick= (): void => {
    sidebarobj.hide();
}
 const openClick = (): void=> {
    sidebarobj.element.style.visibility = '';
    sidebarobj.show();
}
const onCreated = (): void => {
    sidebarobj.element.style.visibility = '';
}
    const scrollTo = (): void => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }
     let width: string = '40%';
     let target: string = '.main-content';
     let mediaQuery: string = '(min-width: 600px)';
     let fields: any = {dataSource: data, id: 'nodeId', text: 'nodeText', child: 'nodeChild'}

    
     
    
    const toggleDarkMode = (): any =>  {
        document.documentElement.classList.toggle('dark-mode')
        // document.getElementById('not-dark').classList.toggle('inverse-dark')
        // document.getElementById('not').classList.toggle('inverse-dark')
        // var x = document.getElementsByClassName('img-pro')
        // for(let i = 0; i < x.length; i += 1) {
        //   x.item(i).classList.toggle("inverse-dark");
        // }
        
        if (document.documentElement.classList.contains('dark-mode'))
          localStorage.setItem('mode', 'Dark')
        else
          localStorage.setItem('mode', 'Light')
        }
    return (
        <div className="container-fluid  vw-100 vh-100">
            <span id="hamburger" className="e-icons menu default  d-sm-block d-md-none " onClick={toggleClick}></span>
                         <SidebarComponent style={{visibility:'hidden'}} id="default-sidebar"  closeOnDocumentClick={true}   showBackdrop={true} type='Push' ref={Sidebar => setSidebarobj(Sidebar)} width={width} >
                            <div className="title-header position-relative">
                                <span id="close" className="m-2 e-icons position-absolute top-0 end-0 " onClick={closeClick}></span>
                                <SwitchComponent change={toggleDarkMode} cssClass="m-2 position-absolute top-0 start-0 d-sm-block d-md-none" id="checked" ref={scope => setSwitch(scope)} checked={false}></SwitchComponent>
                                </div>  
                            <ListViewComponent id="listview"  cssClass='text-center' dataSource={data} template={listTemplate} />    
                        </SidebarComponent>
                        <Particles />
                        <SwitchComponent change={toggleDarkMode} cssClass="switch position-sticky mt-4 sticky-top d-none d-md-block" id="checked" ref={scope => setSwitch(scope)} checked={false}></SwitchComponent>
            <div  className="container-fluid d-flex flex-column align-items-center justify-content-center overflow-hidden  text-center h-100">
                <img  id="not-dark" onClick={scrollTo} alt="Desktop Logo" title="Desktop Logo" className="img-fluid mx-auto  w-auto d-none d-md-block" src="Moe Media_full color logo white bg.png"></img>
            <img id="not-dark" onClick={scrollTo} alt="Mobile Logo" title="Mobile Logo" className="img-fluid mx-auto  w-auto  d-sm-block d-md-none " src="logo-for-mobile.png"></img>
            <h2  className="w-0  d-none d-md-block  text-center line-test anim-typewriter text-responsive text-nowrap">you dream it, we build it...</h2> 
            <p  id='header-112' className=" d-sm-block d-md-none  line-test text-center  anim-typewriter text-responsive text-nowrap">you dream it, we build it...</p> 
            </div>
        </div>
    )
    
}

export default Header;