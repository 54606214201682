import React, { useState, useEffect, useRef } from 'react';
import { contact, social } from '../../helpers/profile';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ChipDirective, ChipListComponent, ChipsDirective, ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { enableRipple, rippleEffect } from '@syncfusion/ej2-base';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useForm, ValidationError,  } from '@formspree/react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';


const Contact: React.FC = () =>{
    // const [fieldErrors, setFieldErrors] = useState({});
    // const [firstName, setFirstName] = useState('');
    // const [email, setEmail] = useState('')
    // const [subject, setSubject] = useState('');
    // const [message, setMessage] = useState('');
   const [state, handleSubmit] = useForm("xzbkkqjl");
   const toastObj:any = useRef();
//    const created = () => {
//       toastObj.current.show();
//    }

const position = {X: "Right"}

    const textboxRef:any = useRef();
    const onFocus = () => {
       textboxRef.current.respectiveElement.style.height = "120px";
    }
    //tab content for forms!!!
    const tabHeader: Array<{}> = [{text: 'General discussion'}, {text:'Business website'}, {text:'E-commerce website'}, {text:'Mobile app'}]
    const generalDiscussion = () => {
        return (
            <div className="col-12 col-sm-6 half paper">
            <form
            onSubmit={handleSubmit}
            >

                <TextBoxComponent
              placeholder="Name"
              type="text"
              name="Name"
              cssClass="e-outline "
              floatLabelType="Auto"
            /> 
       
              <TextBoxComponent
              placeholder="Email Address" 
              type="text"
              name="email"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                 <TextBoxComponent
              placeholder="Subject"
              type="text"
              name="subject"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                <TextBoxComponent
                ref={textboxRef }
                multiline={true}
                focus={onFocus}
                placeholder="Message"
                type="text"
                name="Message"
                cssClass="e-outline"
                floatLabelType="Auto"

              />     
            <ButtonComponent type="submit" cssClass='e-primary'>Submit</ButtonComponent>
             </form>    
            </div>
        )
    }
    const businessWebsite = () => {
        return (
     <div className="col-12  half paper">
                <form
            onSubmit={handleSubmit}
            >
                
                <TextBoxComponent
              placeholder="Name"
              type="text"
              name="Name"
              cssClass="e-outline "
              floatLabelType="Auto"
            /> 
       
              <TextBoxComponent
              placeholder="Email Address" 
              type="text"
              name="email"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                 <TextBoxComponent
              placeholder="Business Website"
              type="text"
              name="subject"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                        <div className="questions">
                        <div>
                            What is the purpose of the website?
                        </div>
                        <ChipListComponent  name="BW-Question1" id="chip-choice" selection="Single" cssClass="e-outline" >
                                <ChipsDirective>
                                    <ChipDirective text="Build trust" ></ChipDirective>
                                    <ChipDirective text="Reach audience" ></ChipDirective>
                                    <ChipDirective text="Generate leads and sell"></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                            </div>
                            <div className="questions">
                        <div>
                            Do you need help with branding?
                        </div>
                        <ChipListComponent  name="BW-Question2" id="chip-choice" selection="Single"  cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                            </div>
                            <div className="questions">
                <div>
                    How many products or services do you plan to sell?
                </div>
                     <ChipListComponent name="BW-Question3" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="1-10" ></ChipDirective>
                                    <ChipDirective text="10-50" ></ChipDirective>
                                    <ChipDirective text="50"></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                    Custom design to a template?
                </div>
                     <ChipListComponent name="BW-Question4" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Custom design" ></ChipDirective>
                                    <ChipDirective text="Template design" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                    Do you have an existing website?
                </div>
                <ChipListComponent name="BW-Question5" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                    Do you need a blog with your business website?
                </div>
                     <ChipListComponent name="BW-Question6" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                    Do you need to manage multiple languages?
                </div>
                <ChipListComponent name="BW-Question7" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                    Do you want the site to be structures for better Search engine visibility?
                </div>
                <ChipListComponent name="BW-Question8" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Do you want professional analytics tools to track conversions? 
                </div>
                <ChipListComponent name="BW-Question9" id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent> 
                     </div>
                     <ButtonComponent type="submit" cssClass='e-primary'>Submit</ButtonComponent>
                     </form>
      </div>
        )
    }
    const eCommerceWebsite = () => {
        return (
            <div className="col-12  half paper">
                       <form
            onSubmit={handleSubmit}
            >
                    <TextBoxComponent
              placeholder="Name"
              type="text"
              name="Name"
              cssClass="e-outline "
              floatLabelType="Auto"
            /> 
       
              <TextBoxComponent
              placeholder="Email Address" 
              type="text"
              name="email"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                 <TextBoxComponent
              placeholder="E-Commerce Website"
              type="text"
              name="subject"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                        <div className="questions">
                        <div>
                        Custom design or a template?
                        </div>
                        <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                            </div>
                            <div className="questions">
                        <div>
                        How many languages will your store have?
                        </div>
                            <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="1" ></ChipDirective>
                                    <ChipDirective text="2" ></ChipDirective>
                                    <ChipDirective text="3" ></ChipDirective>
                                    <ChipDirective text="More" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                            </div>
                            <div className="questions">
                <div>
                How many stock units do you plan to sell?
                </div>
                     <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="1 - 1000" ></ChipDirective>
                                    <ChipDirective text="1000 - 10 000" ></ChipDirective>
                                    <ChipDirective text="10 000+" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Do you need user registration?
                </div>
                <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Will you be accepting payments?
                </div>
                <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Do you need multi-currency support?
                </div>
                <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Do you need data migration from an existing store or a website?
                </div>
                <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Do you have physical stores and need the store locator?
                </div>
                <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <div className="questions">
                <div>
                Do you want the new website to be optimized for speed and performance?
                </div>
                <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                     </div>
                     <ButtonComponent type="submit" cssClass='e-primary'>Submit</ButtonComponent>
                     </form>
      </div>
        )
    }
    
   
   

    const mobileApp = () => {
        return (
            <div className="col-12  half paper">
                       <form
            onSubmit={handleSubmit}
            >
                    <TextBoxComponent
              placeholder="Name"
              type="text"
              name="Name"
              cssClass="e-outline "
              floatLabelType="Auto"
            /> 
       
              <TextBoxComponent
              placeholder="Email Address" 
              type="text"
              name="email"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                 <TextBoxComponent
              placeholder="Mobile App"
              type="text"
              name="subject"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
                <div className="questions">
                <TooltipComponent position="TopCenter" content="Do you need help planning your product roadmap, technical stack requirements, brand positioning and competitive analysis" target="#target">
                        <div>
                        Conceptual planning<span className="e-icons e-search-icon" id="target">?</span>
                        </div>
                        </TooltipComponent>
                        <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                </div>
                <div className="questions">
                <TooltipComponent position="TopCenter" content="Do you need help creating your feature specification, understanding use cases and A/B testing early design ideas" target="#target">
                        <div>
                        Planning features & wireframing<span className="e-icons e-search-icon" id="target">?</span>
                         </div>
                  </TooltipComponent>
                  <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                         </div>
                <div className="questions">
                <TooltipComponent position="TopCenter" content="Do you require hi-fidelity designs inclusive of clickable prototypes and rich UX animations which mirror your final app. This could easily be used for pitch desks/ marketing material before any code is actually written. " target="#target">
                       
                        <div>
                        Mobile App Design<span className="e-icons e-search-icon" id="target">?</span>
                        </div>
                        </TooltipComponent>
                        <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                </div>
                <div className="questions">
                <TooltipComponent position="TopRight" 
                        tipPointerPosition='Start'
                        content="Do you need to publish your app on the App Stores including ensuring you have all the necessary content for organic App Store SEO " 
                        target="#target">
                        <div>
                        Launch on app stores<span className="e-icons e-search-icon" id="target">?</span>
                        </div>
                        </TooltipComponent> 
                        <ChipListComponent id="chip-choice" selection="Single" cssClass="e-outline"  >
                                <ChipsDirective>
                                    <ChipDirective text="Yes" ></ChipDirective>
                                    <ChipDirective text="No" ></ChipDirective>
                                </ChipsDirective>
                            </ChipListComponent>
                </div>
                <ButtonComponent type="submit" cssClass='e-primary'>Submit</ButtonComponent>
                </form>
                </div>
        )
    }


        useEffect(() =>{
             // To enable ripple in checkbox/radio type ButtonGroup.
    let buttons: NodeListOf<Element> = document.querySelectorAll('label.e-btn');
    let button: HTMLElement;
    for (let i: number = 0; i < buttons.length; i++) {
        button = buttons.item(i) as HTMLElement;
        rippleEffect(button, { selector: '.e-small' });

    }
    if (state.succeeded) {
        
        toastObj.current.show({ title: 'Success!', content: 'Your message has been sent successfully.', cssClass: 'e-toast-success', icon: 'e-success toast-icons' })
       }
        }, [state.succeeded])
        
    return (
        <div className="parallax">
                <div data-aos="zoom-in-up" data-aos-once="true" className="git-form">
                <ToastComponent ref={toastObj} id='toast_type' position={position} ></ToastComponent>
                <div className="git-head-div">
                    <h1 id="Contact" className="git-head">Get in touch</h1>
                </div>
                <TabComponent  cssClass="center-tab"  heightAdjustMode="Content" overflowMode="Popup" >
                    <TabItemsDirective>
                        <TabItemDirective header={tabHeader[0]} content={generalDiscussion} />
                        <TabItemDirective header={tabHeader[1]}content={businessWebsite} />
                        <TabItemDirective  header={tabHeader[2]} content={eCommerceWebsite} />
                        <TabItemDirective header={tabHeader[3]} content={mobileApp}/>
                    </TabItemsDirective>
                </TabComponent>
        </div>
            <p id="not-dark" className="Copy">2021 © Copyright  <strong>{contact.copyright}</strong>. All Rights Reserved</p>
        </div>
    )
    
}

export default Contact
